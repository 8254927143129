class Cursor {
    constructor() {
        this.cursor = document.querySelector('.cursor');
        this.smallSquareEl = document.querySelector('.cursor .square-small');
        this.bigSquareEl = document.querySelector('.cursor .square-big');

        this.mouse = { x: 0, y: 0 };
        this.smallSquare = { x: 0, y: 0 };
        this.bigSquare = { x: 0, y: 0 };

        this.smallSpeed = 0.5;
        this.bigSpeed = 0.15;

        this.isInit = false;
    }

    init() {
        this.events();
        this.tick();

        this.isInit = true;
    }

    events() {
        window.addEventListener('mousemove', e => {
            this.mouse.x = e.x;
            this.mouse.y = e.y;
        });

        document.querySelectorAll('.hide-cursor').forEach(el => {
            el.addEventListener('mouseenter', () => {
                this.cursor.classList.add('hide');
            });
            el.addEventListener('mouseleave', () => {
                this.cursor.classList.remove('hide');
            });
        });
    }

    tick() {
        // Move big circle
        this.bigSquare.x += (this.mouse.x - this.bigSquare.x) * this.bigSpeed;
        this.bigSquare.y += (this.mouse.y - this.bigSquare.y) * this.bigSpeed;
        this.bigSquareEl.style.transform = `translate(${this.bigSquare.x}px, ${this.bigSquare.y}px)`;

        // Move small circle
        this.smallSquare.x +=
            (this.mouse.x - this.smallSquare.x) * this.smallSpeed;
        this.smallSquare.y +=
            (this.mouse.y - this.smallSquare.y) * this.smallSpeed;
        this.smallSquareEl.style.transform = `translate(${this.smallSquare.x}px, ${this.smallSquare.y}px)`;

        window.requestAnimationFrame(this.tick.bind(this));
    }
}

export default new Cursor();
