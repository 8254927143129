import { Application } from '@splinetool/runtime';

const projectUrl =
    'https://prod.spline.design/stXXypZ5W4pk-Mvz/scene.splinecode';

class Spline {
    constructor() {
        this.canvas = document.getElementById('webgl');
        this.spline = new Application(this.canvas);
        this.isLoaded = false;
        this.isLoading = false;
    }

    init() {
        return new Promise((resolve, reject) => {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            console.log('init');
            this.spline.load(projectUrl).then(() => {
                this.isLoaded = true;
                resolve({ status: 'loaded' });
            });
        });
    }

    play() {
        if (this.isLoaded) {
            this.spline.play();
        }
    }

    destroy() {
        this.spline.stop();
    }
}

export default new Spline();
