import { disableScroll, enableScroll, getScrollbarWidth } from '../helpers.js';

document.documentElement.style.setProperty(
    '--scrollbar-width',
    getScrollbarWidth() + 'px'
);

class Modal {
    constructor() {
        this.triggers = document.querySelectorAll('[data-modal-open]');

        this.triggers.forEach(trigger => this.init.bind(this)(trigger));
    }

    init(trigger) {
        const target = trigger.dataset.modalOpen;
        const modal = document.querySelector(`[data-modal="${target}"]`);
        if (!modal) return;

        const close = modal.querySelector('.modal__close');
        const overlay = modal.querySelector('.modal__overlay');
        const content = modal.querySelector('.modal__content');

        trigger.addEventListener('click', () => this.showModal(modal));

        close && close.addEventListener('click', () => this.hideModal(modal));

        overlay &&
            overlay.addEventListener('click', () => this.hideModal(modal));

        // Scrolling events
        content &&
            content.addEventListener('wheel', e => {
                e.preventDefault();
                content.scrollTop += e.deltaY;
            });

        // Key event
        document.addEventListener('keydown', e => {
            if (e.keyCode == 27 && modal.classList.contains('active')) {
                this.hideModal(modal, tl);
            }
        });
    }

    // Methods
    showModal(modal) {
        if (modal.classList.contains('active')) return;
        modal.classList.add('active');

        // Disable global scroll
        disableScroll();
    }

    hideModal(modal) {
        if (!modal.classList.contains('active')) return;
        modal.classList.remove('active');

        // Reset global scroll
        enableScroll();
    }
}

export default new Modal();
