import './modules/SmoothScroll.js';
import './modules/AnimateLayout.js';
import './modules/Modal.js';
import './modules/ScrollToJoin.js';
import AnimateContent from './modules/AnimateContent.js';
import Cursor from './modules/Cursor.js';
import MagneticButton from './modules/MagneticButton.js';
import Spline from './modules/Spline.js';
import Gallery from './modules/Gallery.js';
import Preloader from './modules/Preloader.js';

let currentWidth = window.innerWidth;

const launchApp = async () => {
    Preloader.show();

    Gallery.init();

    // Webgl
    if (currentWidth > 768) {
        Cursor.init();
        MagneticButton.init();
        await Spline.init();
    }

    Preloader.hide();

    AnimateContent.init();

    window.addEventListener('resize', async () => {
        if (window.innerWidth !== currentWidth) {
            if (window.innerWidth > 768 && currentWidth <= 768) {
                if (!Cursor.isInit) Cursor.init();
                if (!MagneticButton.isInit) MagneticButton.init();
                if (!AnimateContent.isInit) AnimateContent.init();

                if (Spline.isLoaded) {
                    Spline.play();
                } else {
                    Preloader.show();
                    await Spline.init();
                    Preloader.hide();
                }
            } else if (window.innerWidth <= 768 && currentWidth > 768) {
                Spline.destroy();
            }
            currentWidth = window.innerWidth;
        }
    });
};

document.addEventListener('DOMContentLoaded', function (event) {
    window.addEventListener(
        'load',
        function (e) {
            window.requestAnimationFrame(launchApp);
        },
        false
    );
});
