import Lenis from '@studio-freight/lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

let lenis;

class LenisModule {
    constructor() {
        lenis = new Lenis();

        lenis.on('scroll', ScrollTrigger.update);
        gsap.ticker.add(time => {
            lenis.raf(time * 1000);
        });
    }
}

export { lenis };

export default new LenisModule();
