import { lenis } from './modules/SmoothScroll.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const getScrollbarWidth = () => {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
};

export const disableScroll = () => {
    if (ScrollTrigger.isTouch == 1) return;
    const scrollbarWidth = getScrollbarWidth();
    lenis.stop();
    // document.body.style.paddingRight = `${scrollbarWidth}px`;
};

export const enableScroll = () => {
    if (ScrollTrigger.isTouch == 1) return;
    lenis.start();
    // document.body.style.paddingRight = '';
};
