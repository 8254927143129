class Preloader {
    constructor() {
        this.preloader = document.querySelector('.preloader');
    }

    hide() {
        this.preloader.classList.add('preloader--hidden');
    }

    show() {
        this.preloader.classList.remove('preloader--hidden');
    }
}

export default new Preloader();
