class Gallery {
    constructor() {
        this.galleryLoops = document.querySelectorAll('.gallery-loop');
    }

    init() {
        this.galleryLoops.forEach(loop => {
            // Duplicate inner html
            const innerHtml = loop.innerHTML;
            loop.innerHTML = innerHtml + innerHtml;
        });
    }
}

export default new Gallery();
