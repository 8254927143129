class MagneticButton {
    constructor() {
        this.buttons = document.querySelectorAll('.button');
        this.speed = 0.07;

        this.isInit = false;
    }

    init() {
        this.buttons.forEach(button => {
            this.makeMagnetic(button);
        });
        this.isInit = true;
    }

    makeMagnetic(button) {
        const deltaMouse = { x: 0, y: 0 };
        const buttonPosition = { x: 0, y: 0 };

        button.addEventListener('mouseleave', e => {
            deltaMouse.x = 0;
            deltaMouse.y = 0;
        });

        button.addEventListener('mousemove', e => {
            const buttonRect = button.getBoundingClientRect();
            const relMouse = {
                x: e.clientX - buttonRect.left,
                y: e.clientY - buttonRect.top,
            };

            const buttonRadius = buttonRect.width / 2;
            deltaMouse.x = (relMouse.x - buttonRect.width / 2) / buttonRadius;
            deltaMouse.y = (relMouse.y - buttonRect.height / 2) / buttonRadius;
        });

        const tick = () => {
            const newX = (deltaMouse.x * 40 - buttonPosition.x) * this.speed;
            const newY = (deltaMouse.y * 40 - buttonPosition.y) * this.speed;

            if (Math.abs(newX) < 0.0001) buttonPosition.x = 0;
            else buttonPosition.x += newX;
            if (Math.abs(newY) < 0.0001) buttonPosition.y = 0;
            else buttonPosition.y += newY;
            button.style.translate = `${buttonPosition.x}px ${buttonPosition.y}px`;

            window.requestAnimationFrame(tick);
        };
        tick();
    }
}

export default new MagneticButton();
